.experience-info{
  position: absolute;
  z-index: 17;
  left:30px;
  bottom:30px;
  width:calc(100% - 280px);
  user-select: none;
}
.experience-info-top{
  position: relative;
}

.experience-info-order{
  position: relative;
  font-family: "Voltaire";
  letter-spacing: 3px;
  color:#B297A2;
}

.experience-info-order::after{
  position: absolute;
  content: "";
  width : 30px;
  height: 1px;
  background-color: #B297A2;
  top: 8px;
  left: 25px;
}

.experience-info-title{
  position: relative;
  font-family: "Yeseva";
  font-size: 15px;
  color:#B297A2;
  width:100%;

}

.experience-info-instructions{
  position: relative;
  font-family: "Voltaire";
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 2px;
  color:#B297A2;
  width:100%;
  margin-top: 5px;
}
