.about-block{
    position : absolute;
    width: 100%;
    height: 100%;
    background-color: #F8F0E3;
}


.about-block-info{
    position:absolute;
    left:50%;
    top:50%;
    width:70%;
    transform: translate(-50%, -50%);
}

@media (min-width: 768px){
    .about-block-info{
        max-width: 700px;
    }
}

.about-block-title{
    position: relative;
    font-family: "Yeseva";
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    margin-bottom: 20px;
    color: #AA6D80;
    left: 50%;
    transform: translate(-50%, 0%);
}

@media (min-width: 768px){
    .about-block-title{
        font-size: 19px;
    }
}


.big{
    font-family: "Voltaire";
    font-size: 26px;
    text-transform: UPPERCASE;
    letter-spacing: 3px;
    line-height: 1.2;
}

@media (min-width: 768px){
    .big{
        font-size: 67px;
        letter-spacing: 9px;
    }
}

.about-block-description{
    position : relative;
    font-family: "Voltaire";
    font-size: 12px;
    text-align: center;
    line-height: 1.8;
    width: 100%;
    color: #AA6D80;
}
@media (min-width: 768px){
    .about-block-description{
        font-size: 16px;
        letter-spacing: .2px;
    }
}

.about-block-description a{
    text-decoration: none;
    color:#CA495F;
}


.linkAbout{
    position:relative;
    display:inline;
    color:#CA495F;
    box-sizing: border-box;
    font-family: 'Voltaire';
    padding: 0px 0px;
    letter-spacing: 0px;
    cursor: pointer;
}
.linkAbout::after{
    position:absolute;
    content:"";
    width:0%;
    height:2px;
    left:0;
    bottom:-2px;
    background-color:#CA495F;
    transition: width .2s;
}

.linkAbout:hover::after{
    width:100%;
}

.about-block-close {
    position : relative;
    font-family: "Voltaire";
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    width: 30px;
    height: 30px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.about-block-close::before {
    position: absolute;
    content: "";
    width:100%;
    height:100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: #AA6D80;
    transition: width .2s, height .2s, left .2s, top .2s;
}

.about-block-close svg{
    fill: #AA6D80;
    transition: transform .2s;
    cursor: pointer;
    transform: scale(.3, .3);
}

.about-block-close:hover svg{
  transform: scale(.5, .5);
  fill : #CA495F;
}

.about-block-close:hover::before{
  width:120%;
  height:120%;
  border-color: #CA495F;
}

.lightSignature{
    margin-top: 20px;
    font-size: 12px;
}

.size-disclaimer{
    position: absolute;
    z-index: 19;
    width: 100%;
    height: 100%;
    background-color: #2c2b2a;
}

.size-disclaimer .about-block-info{
    top:30%;
}
