.bgrHolder{
  position: absolute;
  width:100%;
  height:100%;
  overflow: hidden;
}

.bgr{
  position: absolute;
  width:100%;
  height:100%;
}

.bgr1{
  background: #ebe5e7;
  z-index: 0;
}

.bgr2{
  background: blue;
  z-index:1;
  width:0%;
}

.world{
  position:absolute;
  width:100%;
  height:100%;
  overflow:hidden;
  z-index:2;
}
#mycanvas{
  position: absolute;
  font-family: 'Yeseva';
  background-color: rgba(255,0,0,.1);
  width:512px;
  height:512px;
  bottom: 0;
  display:none;
}