
@font-face {
    font-family: 'Yeseva';
    src: url('/assets/fonts/YesevaOne-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Voltaire';
    src: url('/assets/fonts/Voltaire-Regular.ttf') format('truetype');
}

html.not-ready {
    body {
        opacity: 0;
    }
}

body{
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

div{
    box-sizing: border-box;
}
.wrapper {
  position:absolute;
  width:100%;
  height:100%;
  overflow: hidden;
  background: rgb(224, 218, 205);
}
.dragon-score {
    position: absolute;
    font-family: 'Yeseva';
    left:50%;
    top:50%;
    transform: translate(-50%, -100%);
    font-size: 300px;
    color: #A33F51;
    user-select: none;
    opacity:0;
    display:none;
}

.gameover-instructions {
    position: absolute;
    font-family: 'Voltaire';
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    width:90%;
    left:50%;
    top:40%;
    transform: translate(-50%, -50%);
    font-size: 120px;
    color: #F8A375;
    user-select: none;
    z-index: 100;
    opacity:0;
    display:none;
}

.top-score {
    position: absolute;
    font-family: 'Voltaire';
    text-align: center;
    left:50%;
    top:50px;
    transform: translate(-50%, 0%);
    font-size: 30px;
    color: #F8A375;
    user-select: none;
    z-index: 101;
    opacity:0;
    display:none;
}

.lovie-banner {
  position: absolute;
  z-index: 20;
  right: 0;
}
